import React, { useState, useEffect } from "react";
import { string, arrayOf, shape } from "prop-types";
import IntegrationCard from "./integration-card";
import Heading from "../Base/HeadingBuilder";
import CtaSecondary from "../Base/CtaSecondary";

const IntegrationsOverview = ({ className, title, subTitle, integrations }) => {
  const [expanded, setExpanded] = useState(false);
  const [itemsToRender, setItemsToRender] = useState(integrations.slice(0, 6));

  useEffect(() => {
    setItemsToRender(expanded ? integrations : integrations.slice(0, 6));
  }, [expanded]);

  return (
    <section className={`${className} relative text-center px-8`}>
      <Heading className="text-black" injectionType={2} withBlueSymbol>
        {title}
      </Heading>

      <p className="text-black-200 text-p max-w-[880px] mx-auto">{subTitle}</p>

      <div
        className={`
        grid grid-cols-1 md:grid-cols-2 lg:flex lg:flex-row lg:flex-wrap 
        gap-8 justify-center items-center place-items-center
        mt-12
      `}
      >
        {itemsToRender.map((integration) => (
          <IntegrationCard key={integration.company} {...integration} />
        ))}
      </div>

      {!expanded && (
        <CtaSecondary
          className="mx-auto mt-10"
          title="Show more"
          large
          onClick={() => setExpanded(!expanded)}
        />
      )}
    </section>
  );
};

IntegrationsOverview.propTypes = {
  className: string,
  title: string,
  subTitle: string,
  integrations: arrayOf(
    shape({
      company: string,
      description: string,
      industry: string,
      logo: string,
    })
  ),
};

IntegrationsOverview.defaultProps = {
  className: "",
  title: "",
  subTitle: "",
  integrations: [],
};

export default IntegrationsOverview;
