import React, { useState } from "react";
import { string, any } from "prop-types";
import { motion } from "framer-motion";
import useStartMotion from "../../hooks/use-start-motion";

const IntegrationCard = ({ company, industry, logo, description }) => {
  const [flipped, setFlipped] = useState(false);
  const { ref } = useStartMotion();

  const infoBlock = () => (
    <motion.div
      className="absolute w-full h-full left-0 top-0"
      ref={ref}
      animate={{
        rotateY: flipped ? 90 : 0,
        opacity: flipped ? 0 : 1,
      }}
      transition={{
        rotateY: { delay: 0.1 },
      }}
    >
      <div className="text-black text-[12px] mt-8">{industry}</div>
      <div className="text-black text-[18px] font-bold mt-1">{company}</div>
    </motion.div>
  );

  const descriptionBlock = () => (
    <motion.div
      className="text-black-200 text-[12px] mt-4 absolute w-full h-full left-0 top-0"
      ref={ref}
      animate={{
        rotateY: flipped ? 0 : 90,
        opacity: flipped ? 1 : 0,
      }}
      transition={{
        rotateY: { delay: 0.1 },
      }}
    >
      {description}
    </motion.div>
  );

  return (
    <button
      role="button"
      onClick={() => setFlipped(!flipped)}
      className={`int-card-wrapper
      outline-none border-solid border-black-700 rounded-lg
      px-6 border-[1px]
      max-w-[353px] min-h-[285px] w-full cursor-pointer
      transition-all duration-300 delay-100
      flex flex-col items-center
      ${flipped ? "bg-black-700" : "bg-white"}
      `}
    >
      <motion.img
        src={logo}
        alt="logo"
        className="mt-8"
        ref={ref}
        animate={{
          rotateY: flipped ? 0 : 360,
        }}
        transition={{ duration: 0.8 }}
      />

      <div className="relative w-full h-full">
        {infoBlock()}

        {descriptionBlock()}
      </div>
    </button>
  );
};

IntegrationCard.propTypes = {
  company: string.isRequired,
  industry: string.isRequired,
  logo: string.isRequired,
  description: string.isRequired,
};

IntegrationCard.defaultProps = {};

export default IntegrationCard;
